import { useState, useEffect } from 'react';
import GoogleLoginContainer from '@leecheuk/react-google-login';
import axios from 'axios';
import { gapi } from 'gapi-script';
import GoogleLogin from '../GoogleLogin';
import EmailLogin from '../EmailLogin';

import './index.scss';

const Login = ({ handleModal, authState }) => {
  const [userInfo, setInfo] = useState();
  const [loginWithGoogle, setLoginWithGoogle] = useState(false);
  const [loginWithEmail, setLoginWithEmail] = useState(false);
  const [activeFirst, setFirstActive] = useState(true);
  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId:
          '131764235081-rjffbv1o66kin7mqmokadcjq64jp1bfb.apps.googleusercontent.com',
        scope: '',
      });
    };
    gapi.load('client:auth2', initClient);
  }, []);

  const onSuccess = async (res) => {
    doGoogleLogin();
    const { profileObj } = res;
    localStorage.setItem('google_profile', JSON.stringify(profileObj));
  };

  const onFailure = (res) => {
    console.log(res);
  };

  const handleChange = (e) => {
    setInfo({ ...userInfo, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    const googleId = JSON.parse(localStorage.getItem('google_login'));
    try {
      const {
        data: { tokens },
      } = await axios.post(`v1/users/${googleId}`, userInfo);
      handleModal();
      localStorage.setItem('token', JSON.stringify(tokens));
    } catch (error) {
      throw error;
    }
  };

  const doEmailLogin = () => {
    setLoginWithEmail(true);
    setFirstActive(false);
  };

  const doGoogleLogin = () => {
    setLoginWithGoogle(true);
    setFirstActive(false);
  };

  return (
    <div className="login">
      <p>Sign up or log in:</p>
      {loginWithEmail && (
        <EmailLogin handleModal={handleModal} authState={authState} />
      )}
      {loginWithGoogle && <GoogleLogin handleModal={handleModal} />}
      {activeFirst && (
        <div className="login-buttons-wrapper">
          <button className="email-login" onClick={doEmailLogin}>
            <i className="gg-mail"></i>
            Continue with email
          </button>
          <GoogleLoginContainer
            clientId="131764235081-rjffbv1o66kin7mqmokadcjq64jp1bfb.apps.googleusercontent.com"
            onSuccess={onSuccess}
            onFailure={onFailure}
            cookiePolicy={'single_host_origin'}
            buttonText="Continue with Google"
            className="google-container"
          ></GoogleLoginContainer>
        </div>
      )}
    </div>
  );
};

export default Login;
