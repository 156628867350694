import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Splash from '../Splash/Splash';
import Generator from '../Generator/Generator';
import PersonalPage from '../PersonalPage';
import NotFound from '../NotFound';

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Generator />} />
        <Route path="/generator/" element={<Generator />} />
        <Route path="/:username" element={<PersonalPage />} />
        <Route path="/not-found" element={<NotFound />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
