import React from 'react';
import './index.scss';

const NotFound = () => {
  return (
    <section className="not-found">
      <h1>Page Not Found</h1>
      <p className="large">Sorry, this page does not exist</p>
    </section>
  );
};

export default NotFound;
