import React, { useState, useEffect, useRef } from 'react';
import api from '../../utils/api';
import setAuth from '../../utils/setAuth';
import CountUp from 'react-countup';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import Login from '../Login';
import SignUp from '../SIgnup';

import './Generator.scss';

export default function Generator() {
  const navigator = useNavigate();
  const [data, setData] = useState(null);
  const [description, setDescription] = useState('');
  const [displayedTag, setDisplayedTag] = useState('');
  const [isSpecialChar, setSpecialChar] = useState(false);
  const [isSharpSymbol, setSharpSymbol] = useState(false);
  const [isDescInputFocus, setDescInputFocus] = useState(false);
  const [isTagInputFocus, setTagInputFocus] = useState(false);
  const [tag, setTag] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fadeInBtn, setFadeInBtn] = useState(false);
  const [displaySubmit, setDisplaySubmit] = useState(true);
  const [displayClear, setDisplayClear] = useState(false);
  const [showStyles, setShowStyles] = useState(false);

  const [error, setError] = useState(null);
  const [displayImage, setDisplayImage] = useState(false);
  const [imgUrl, setImgUrl] = useState(null);
  const [thumb1Url, setThumb1Url] = useState(null);
  const [thumb2Url, setThumb2Url] = useState(null);
  const [thumb3Url, setThumb3Url] = useState(null);
  const [seed, setSeed] = useState(null);
  const [responseStatus, setResponseStatus] = useState(null);
  const [responseStatusMsg, setResponseStatusMsg] = useState(null);
  const [checkedState, setCheckedState] = React.useState({
    fantasy: false,
    anime: false,
    pencil: false,
    nouveau: false,
    watercolor: false,
    deco: false,
    acrylic: false,
  });

  const [isOpen, setOpen] = useState(false);
  const [isLogin, setLogin] = useState(false);
  const [isSignUp, setSignUp] = useState(false);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [nickName, setNickname] = useState('');
  const [showSavedToast, setShowSavedToast] = useState(false);
  const [savePrompt, setSavePrompt] = useState(null);

  useEffect(() => {
    const userInfo = localStorage.getItem('user_info');
    userInfo ? setAuthenticated(true) : setAuthenticated(false);
    if (userInfo) {
      setNickname(JSON.parse(userInfo).user.username);
    }
    if (isDescInputFocus) descInputRef.current?.focus();
  });

  const createSeedFormat = (url) => {
    let filename = '';
    filename = new URL(url).pathname.split('/').pop();
    let filenameNoExt = filename.substring(0, filename.lastIndexOf('.'));
    return filenameNoExt;
  };

  const loadImage = (url) => {
    return new Promise((res, rej) => {
      const image = new Image();
      image.src = url[0];
      image.onload = () => {
        setDisplayImage(true);
        setImgUrl(image.src);
        setSeed(createSeedFormat(url[0]));
        setThumb1Url(url[0]);
        setThumb2Url(url[1]);
        setThumb3Url(url[2]);
        res();
      };
      image.onerror = (e) => {
        console.warn('image was not loaded', e);
        rej();
      };
    });
  };

  const getData = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/images/${description}/${checkedState.fantasy}/${checkedState.anime}/${checkedState.pencil}/${checkedState.nouveau}/${checkedState.watercolor}/${checkedState.deco}/${checkedState.acrylic}`
      );
      const data = response.data;
      const [tmpPrompt] = response.data.meta.prompt.split(',');
      setSavePrompt(tmpPrompt);
      setTag([]);
      setResponseStatus(response.data.status);
      setResponseStatusMsg(response.data.messege);
      await loadImage(data.output);
      setLoading(false);
      setData(data);
      setDisplaySubmit(false);
      setDisplayClear(true);
    } catch (e) {
      setData(null);
      setLoading(false);
      console.log('api call error ===', e);
      setError(error);
    }
  };

  const handleToggle = ({ target }) =>
    setCheckedState((s) => ({ ...s, [target.name]: !s[target.name] }));

  const handleDescription = (event) => {
    setDisplaySubmit(true);
    setFadeInBtn(true);
    setDescription(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.charCode === 0x20 || event.charCode === 0x2c)
      setSpecialChar(true);
    else setSpecialChar(false);
    if (event.charCode === 0x23) setSharpSymbol(true);
    else setSharpSymbol(false);
  };

  const handleTag = (event) => {
    const editValue = event.target.value.replace(/,/g, ' ');
    if (
      editValue.charAt(editValue.length - 2) === '#' &&
      (isSharpSymbol || isSpecialChar)
    )
      return;
    if (!displayedTag && editValue.charAt(editValue.length - 1) !== '#') {
      setDisplayedTag('#' + editValue);
      return;
    }
    setDisplayedTag(editValue);
    setTag([editValue.replace(/#/g, '')]);
    if (isSpecialChar === true) {
      setDisplayedTag(editValue + '#');
      setSpecialChar(false);
    }
  };

  const handleClick = (event) => {
    setDescription('');
    setTag([]);
    setDisplayClear(false);
    setDisplaySubmit(true);
    setCheckedState({
      fantasy: false,
      anime: false,
      pencil: false,
      nouveau: false,
      watercolor: false,
      deco: false,
      acrylic: false,
    });
  };

  const handleSubmit = (event) => {
    getData();
    setLoading(true);
    event.preventDefault();
  };

  const imgClick = (event, url) => {
    setDescInputFocus(false);
    setImgUrl(url);
    setSeed(createSeedFormat(url));
  };

  const handleOnDescFocus = () => {
    setDescInputFocus(true);
    setTagInputFocus(false);
  };

  const GeneratedDisplay = () => {
    const tagInputRef = useRef();
    useEffect(() => {
      if (isTagInputFocus) tagInputRef.current?.focus();
    }, []);

    const handleOnTagFocus = () => {
      setTagInputFocus(true);
      setDescInputFocus(false);
    };

    return (
      <>
        {data && displayImage ? (
          <>
            <img src={imgUrl} alt="" className={loading ? 'loadingImg' : ''} />
            {isAuthenticated && data && !loading && displayClear && (
              <>
                <input
                  id="tag"
                  ref={tagInputRef}
                  placeholder="#tags"
                  value={displayedTag}
                  onKeyPress={handleKeyPress}
                  onChange={handleTag}
                  onFocus={handleOnTagFocus}
                  type="text"
                  autoComplete="off"
                  className="tag-box"
                  required="required"
                />
                <button className="btn-save save-icon">
                  &nbsp;
                  <i
                    className="fa fa-floppy-o"
                    aria-hidden="true"
                    title="Save image"
                    onClick={saveImageToMyPage}
                  ></i>
                </button>
              </>
            )}
            <div className="thumbsContainer">
              <div className="thumbnail">
                <img
                  src={thumb1Url}
                  className={loading ? 'loadingImg' : ''}
                  alt=""
                  onClick={(event) => imgClick(event, thumb1Url)}
                />
              </div>
              <div className="thumbnail">
                <img
                  src={thumb2Url}
                  className={loading ? 'loadingImg' : ''}
                  alt=""
                  onClick={(event) => imgClick(event, thumb2Url)}
                />
              </div>
              <div className="thumbnail">
                <img
                  src={thumb3Url}
                  className={loading ? 'loadingImg' : ''}
                  alt=""
                  onClick={(event) => imgClick(event, thumb3Url)}
                />
              </div>

              {!isAuthenticated && data && !loading && displayClear && (
                <button onClick={signUpToSaveImage} className="btn-save">
                  <i className="fa fa-floppy-o" aria-hidden="true"></i>Save to
                  my page
                </button>
              )}
            </div>
            {!loading && (
              <>
                <p className="generatedDuration">
                  Images generated using Stability AI in{' '}
                  {data.generationTime ? data.generationTime.toFixed(2) : '0'}{' '}
                  seconds.
                </p>
                <p className="generatedSeed">
                  Seed: <span>{seed}</span>
                </p>
              </>
            )}
          </>
        ) : null}
      </>
    );
  };

  const openModal = () => {
    setOpen(!isOpen);
  };

  const handleLogin = () => {
    try {
      setOpen(!isOpen);

      if (isOpened) {
        inputRef.current?.click();
      }
      setLogin(true);
      setSignUp(false);
    } catch (err) {
      throw err;
    }
  };

  const handleSignUp = () => {
    setOpen(!isOpen);

    if (isOpened) {
      inputRef.current?.click();
    }
    setLogin(true);
    setSignUp(true);
  };

  const handleLogout = () => {
    localStorage.clear();

    if (isOpened) {
      inputRef.current?.click();
      // setIsOpened(false);
    }

    setAuthenticated(false);
    navigator('/generator');
  };

  const handleStyles = () => {
    setShowStyles(!showStyles);
  };

  const handleRandom = () => {
    setDisplaySubmit(true);
    const items = [
      'ruins of a medieval castle',
      'bears playing hockey',
      'gingerbread house',
      'Mayan temple in the jungle',
      'Hooded alien creature',
      'Whimsical Fairy Wonderland',
      'Overgrown city covered in vines and moss',
      'Beautiful flower meadow',
      'Post apocalyptic wonderland',
      'Psychedelic fantasy castle',
      'Desolate wasteland',
      'Gas station on Mars',
      'Cyborg bartender',
      'secret flower garden',
      'Monolith in the desert',
      'Lazy cat taking a nap',
      'Owl sitting on a tree',
    ];
    setDescription(items[Math.floor(Math.random() * items.length)]);
  };

  const saveImageToMyPage = async () => {
    const {
      tokens: {
        access: { token },
      },
    } = JSON.parse(localStorage.getItem('user_info'));

    if (token) {
      setAuth(token);
      const saveTag = tag.toString().split(/[,\s]/);
      await api.post('/images', { imgUrl, prompt: savePrompt, tag: saveTag });
      setShowSavedToast(true);
    }

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const signUpToSaveImage = () => {
    handleSignUp();
  };

  const [isOpened, setIsOpened] = useState(false);

  const inputRef = useRef();
  const descInputRef = useRef();

  return (
    <div className="App">
      <nav role="navigation" className="mobile-wrapper">
        <div id="menuToggle" className="">
          <input
            type="checkbox"
            ref={inputRef}
            onClick={() => setIsOpened(!isOpened)}
          />

          <span></span>
          <span></span>
          <span></span>

          <ul id="menu" className={isOpened ? 'open-menu' : ''}>
            {isAuthenticated ? (
              <a href="#" className="log-out-btn" onClick={handleLogout}>
                <li>
                  <i className="fa fa-sign-out" aria-hidden="true"></i>
                  Log out
                </li>
              </a>
            ) : (
              <>
                <a href="#" className="login-btn" onClick={handleLogin}>
                  <li>
                    <i className="fa fa-sign-in" aria-hidden="true"></i>Login
                  </li>
                </a>
                <a href="#" className="sign-up-btn" onClick={handleSignUp}>
                  <li>Sign up</li>
                </a>
              </>
            )}
          </ul>
        </div>
      </nav>
      <div className="topBar">
        <Link to="/generator">
          <h1>
            Storybook<span>(AI)</span>
          </h1>
        </Link>

        <div className="account-container">
          {isAuthenticated ? (
            <>
              {isAuthenticated && (
                <>
                  <Link to={`/generator`} className="link-mypage">
                    <p>AI Art Generator</p>
                  </Link>
                  <p className="link-mypage">|</p>
                  <Link to={`/${nickName}`} className="link-mypage">
                    <p>My Storybook</p>
                  </Link>
                </>
              )}
              <div className="log-out-btn" onClick={handleLogout}>
                <i
                  className="fa-sharp fa-solid fa-right-from-bracket"
                  aria-hidden="true"
                ></i>
                Log out
              </div>
            </>
          ) : (
            <>
              <div className="login-btn" onClick={handleLogin}>
                Login
              </div>
              <div className="sign-up-btn" onClick={handleSignUp}>
                Sign&nbsp;up
              </div>
            </>
          )}
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        className="login-modal"
        onRequestClose={openModal}
        style={{
          content: {
            backgroundColor: 'bisque',
            height: '23rem',
            width: '500px',
            padding: '2rem',
            borderRadius: '8px',
            color: '#333',
            margin: '2rem',
          },
          overlay: {
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: '999',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '',
          },
        }}
      >
        {isLogin ? (
          <Login handleModal={openModal} authState={isSignUp} />
        ) : (
          <SignUp handleModal={openModal} />
        )}
      </Modal>

      <div className="mobileNavAbove">
        {isAuthenticated && (
          <>
            <Link to={`/generator`} className="link-mypage">
              <p>AI Art Generator</p>
            </Link>
            <p className="link-mypage divide">|</p>
            <Link to={`/${nickName}`} className="link-mypage">
              <p>My Storybook</p>
            </Link>
          </>
        )}
      </div>

      {showSavedToast && (
        <div className="success-msg">
          <i className="fa fa-check"></i>
          Image saved to <Link to={`/${nickName}`}>My Storybook</Link>.
        </div>
      )}

      {responseStatus === 'processing' ? (
        <div className="error-msg">
          <i className="fa fa-times-circle"></i>
          Processing error: {responseStatusMsg}.
        </div>
      ) : null}

      {responseStatus === 'failed' ? (
        <div className="error-msg">
          <i className="fa fa-times-circle"></i>
          API error: {responseStatusMsg}.
        </div>
      ) : null}

      <div className="App-header">
        <h2>AI Art Generator</h2>

        <form onSubmit={handleSubmit} className="imageForm">
          <div>
            <input
              id="description"
              ref={descInputRef}
              placeholder="describe your image here.."
              value={description}
              onChange={handleDescription}
              onFocus={handleOnDescFocus}
              type="text"
              autoComplete="off"
              className="descriptionBox"
              required="required"
            />
          </div>
          <div className="random-wrapper">
            <a href="#" onClick={handleStyles} className="more-styles">
              <i className="fa-solid fa-palette"></i>More styles
            </a>
            <a href="#" onClick={handleRandom}>
              Random
            </a>
          </div>

          {showStyles && (
            <>
              <p className="styles-wrapper">
                Select styles: <span>(optional)</span>
              </p>
              <div className="checkmarkWrapper">
                {Object.keys(checkedState).map((key) => (
                  <div className="optionCheckmarks" key={key}>
                    <input
                      type="checkbox"
                      id={key}
                      onChange={handleToggle}
                      key={key}
                      name={key}
                      data-name={key}
                      checked={checkedState[key]}
                      className="checkboxCustom"
                    />
                  </div>
                ))}
              </div>
            </>
          )}

          <button type="submit" className={fadeInBtn ? 'fadeIn' : ''}>
            <i className="fa-duotone fa-pen-paintbrush" aria-hidden="true"></i>
            {displaySubmit ? 'Create' : 'Re-create'}
          </button>
          {data && displayClear && (
            <button
              type="reset"
              onClick={handleClick}
              className={description ? 'fadeIn clearBtn' : 'clearBtn'}
            >
              Clear
            </button>
          )}
        </form>
        <div
          className={
            description ? 'imgContainer animateContainer' : 'imgContainer'
          }
        >
          {loading && (
            <div className="loadingCounter">
              <div className="terminal">
                <p className="delay1">$ curl ./sd-v1-5.ckpt</p>
                <p className="delay2">Stable Diffusion v1.5 engine running..</p>
                <p className="delay4">Config data successfully loaded.</p>
                <p className="delay5">
                  $ sd.py --prompt '{description.toLowerCase()}'
                </p>
                <p className="delay6">
                  <span className="blink">Processing images... </span>
                </p>
                <p className="delay7">
                  <CountUp
                    delay={2.2}
                    start={0.0}
                    end={50.0}
                    decimals={6}
                    duration={50}
                  />
                </p>
              </div>
            </div>
          )}

          {loading ? (
            <div
              className={
                loading
                  ? 'progressContainer animateProgress'
                  : 'progressContainer'
              }
            >
              <div className="progressBar" />
            </div>
          ) : null}

          {!data ? (
            <img
              src="/images/image-placeholder.jpg"
              className={
                loading ? 'loadingImg placeholderImg' : 'placeholderImg'
              }
              alt=""
            />
          ) : null}

          {responseStatus === 'success' ? <GeneratedDisplay /> : null}
        </div>
      </div>
      <p className="footerText">
        © {new Date().getFullYear()}{' '}
        <a href="https://b-average.com/" target="_blank" rel="noreferrer">
          B Average Inc.
        </a>
      </p>
    </div>
  );
}
