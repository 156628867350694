import axios from 'axios';
import { useState, useEffect } from 'react';

import './index.scss';

const GoogleLogin = ({ handleModal }) => {
  const [userInfo, setUserInfo] = useState();
  const [firstTime, setFirstTime] = useState(false);

  const { email, googleId } = JSON.parse(
    localStorage.getItem('google_profile')
  );
  const handleChange = (e) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const getUserByGoogleId = async () => {
      if (googleId) {
        try {
          const { data } = await axios.get(`v1/users/google/${googleId}`);
          localStorage.setItem('user_info', JSON.stringify(data));
          handleModal(false);
        } catch (error) {
          if (error.response.status === 404) {
            setFirstTime(true);
          }
        }
      }
    };
    getUserByGoogleId();
  }, []);

  const handleSubmit = async () => {
    if (firstTime) {
      const { username } = userInfo;
      const { data } = await axios.post('v1/google', {
        email,
        googleId,
        username,
      });
      localStorage.setItem('user_info', JSON.stringify(data));
    }
    handleModal(false);
  };
  return (
    <div className="google-login">
      <p>Login with Google</p>
      {firstTime && (
        <div className="input-item">
          <p>Username: </p>
          <input type="text" name="username" onChange={handleChange} />
        </div>
      )}
      <button onClick={handleSubmit}>SUBMIT</button>
    </div>
  );
};

export default GoogleLogin;
