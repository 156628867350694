import { useState } from 'react';
import axios from 'axios';

import './index.scss';

const EmailLogin = ({ handleModal, authState }) => {
  const [userInfo, setUserInfo] = useState();
  const [existedUser, setUserState] = useState(false);
  const [stepOne, setStepOne] = useState(true);
  const [stepTwo, setStepTwo] = useState(false);
  const [stepThree, setStepThree] = useState(false);
  const [newState, setNew] = useState(false);
  const [lastStep, setLastStep] = useState(false);
  const [firstTime, setFirstTime] = useState(false);
  const [userId, setUserId] = useState(null);

  const handleChange = (e) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    const { username, email, password, confirmPassword } = userInfo;
    if (stepOne) {
      try {
        const response = await axios.get(`v1/users/user/${email}`);
        setStepOne(false);
        setStepTwo(true);
      } catch (error) {
        if (error.code === 'ERR_BAD_REQUEST') {
          setStepOne(false);
          setStepTwo(true);
          setNew(true);
        }
      }
    }

    if (stepTwo) {
      console.log({ email, password });
    }

    if (stepTwo && newState) {
      if (password !== confirmPassword) {
        window.alert('Second password did not match');
      } else {
        try {
          const { data } = await axios.post('v1/auth/register', {
            email,
            password,
          });
          localStorage.setItem('user_info', JSON.stringify(data));
          setStepTwo(false);
          setFirstTime(true);
        } catch (error) {
          const {
            response: {
              data: { message },
            },
          } = error;
          window.alert(message);
        }
      }
    }

    if (firstTime) {
      const {
        user: { id },
      } = JSON.parse(localStorage.getItem('user_info'));
      const { data } = await axios.patch(`v1/users/${id}`, { username });
      localStorage.setItem('user_info', JSON.stringify(data));
      handleModal(false);
    }

    if (stepTwo && !newState) {
      try {
        const { data } = await axios.post('v1/auth/login', { email, password });
        localStorage.setItem('user_info', JSON.stringify(data));
        handleModal(false);
      } catch (error) {
        window.alert('Wrong password');
      }
    }
  };

  return (
    <div className="email-login">
      <p>Enter your email to log in or register</p>
      {stepOne && (
        <>
          <div className="input-item">
            <input type="text" name="email" onChange={handleChange} />
          </div>
        </>
      )}
      {stepTwo && newState && (
        <>
          <div className="input-item">
            <p className="label-pw">Password: </p>
            <input
              type="password"
              name="password"
              className="input-pw"
              onChange={handleChange}
            />
          </div>
          <div className="input-item">
            <p>Confirm Password: </p>
            <input
              type="password"
              name="confirmPassword"
              className="input-pw"
              onChange={handleChange}
            />
          </div>
        </>
      )}
      {stepTwo && !newState && (
        <>
          {authState && (
            <p className="warning">
              Email exists. Please enter password below.
            </p>
          )}
          <div className="input-item">
            <p>Password: </p>
            <input type="password" name="password" onChange={handleChange} />
          </div>
          {/* <div className="forgot-password" onClick={forgotPassword}>
            Forgot your password?
          </div> */}
        </>
      )}
      {firstTime && (
        <>
          <div className="input-item">
            <p>Username: </p>
            <input type="text" name="username" onChange={handleChange} />
          </div>
        </>
      )}
      <button onClick={handleSubmit}>{lastStep ? 'Login' : 'Next'}</button>
    </div>
  );
};

export default EmailLogin;
