import React, { useState, useEffect } from 'react';
import api from '../../utils/api';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import Login from '../Login';
import SignUp from '../SIgnup';
import ImageContainer from './ImageContainer';
import TagsFilter from './TagsFilter';

import './index.scss';

const PersonalPage = () => {
  const { username } = useParams();
  const [personalData, setPersonalData] = useState({});
  const [isExist, setExistance] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const [filteredTag, setFilteredTag] = useState();
  const [isOpened, setIsOpened] = useState(false);
  const [isAllowed, setAllowance] = useState(false);
  const navigator = useNavigate();
  const [isOpen, setOpen] = useState(false);
  const [isLogin, setLogin] = useState(false);
  const [isSignUp, setSignUp] = useState(false);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [nickName, setNickname] = useState('');

  useEffect(() => {
    const userInfo = localStorage.getItem('user_info');
    userInfo ? setAuthenticated(true) : setAuthenticated(false);
    if (userInfo) {
      setNickname(JSON.parse(userInfo).user.username);
    }
    passUpdatedTags(filteredTags);
    passUpdatedTag(filteredTag);
  });

  const passUpdatedTags = (tags) => {
    setFilteredTags(tags);
  };

  const passUpdatedTag = (tag) => {
    setFilteredTag(tag);
  };

  const openModal = () => {
    setOpen(!isOpen);
  };

  const handleLogin = () => {
    try {
      setOpen(!isOpen);
      setLogin(true);
      setSignUp(false);
    } catch (err) {
      throw err;
    }
  };

  const handleSignUp = () => {
    setOpen(!isOpen);
    setLogin(true);
    setSignUp(true);
  };

  const handleLogout = () => {
    localStorage.clear();
    setAuthenticated(false);
    navigator('/generator');
  };

  useEffect(() => {
    const getUser = async () => {
      try {
        const { data } = await api.get(`images/${username}`);
        setPersonalData(data);
        setImageList(data.image);
        setFilteredTags(data.filter);
        setExistance(true);
        setFilteredTag('All');
      } catch (error) {
        navigator('/not-found');
      }
    };
    getUser();
  }, []);

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('user_info'));
    if (userInfo && userInfo.user.username === username) {
      setAllowance(true);
    }
  });

  return (
    <div className="App">
      <nav role="navigation" className="mobile-wrapper">
        <div id="menuToggle">
          <input type="checkbox" onClick={() => setIsOpened(!isOpened)} />

          <span></span>
          <span></span>
          <span></span>

          <ul id="menu">
            {isAuthenticated ? (
              <a href="#" className="log-out-btn" onClick={handleLogout}>
                <li>Log out</li>
              </a>
            ) : (
              <>
                <a href="#" className="login-btn" onClick={handleLogin}>
                  <li>Login</li>
                </a>
                <a href="#" className="sign-up-btn" onClick={handleSignUp}>
                  <li>Sign up</li>
                </a>
              </>
            )}
          </ul>
        </div>
      </nav>
      <div className="topBar">
        <Link to="/generator">
          <h1>
            Storybook<span>(AI)</span>
          </h1>
        </Link>

        <div className="account-container">
          {isAuthenticated ? (
            <>
              {isAuthenticated && (
                <>
                  <Link to={`/generator`} className="link-mypage">
                    <p>AI Art Generator</p>
                  </Link>
                  <p className="link-mypage">|</p>
                  <Link to={`/${nickName}`} className="link-mypage">
                    <p>My Storybook</p>
                  </Link>
                </>
              )}
              <div className="log-out-btn" onClick={handleLogout}>
                <i
                  className="fa-sharp fa-solid fa-right-from-bracket"
                  aria-hidden="true"
                ></i>
                Log out
              </div>
            </>
          ) : (
            <>
              <div className="login-btn" onClick={handleLogin}>
                Login
              </div>
              <div className="sign-up-btn" onClick={handleSignUp}>
                Sign&nbsp;up
              </div>
            </>
          )}
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        className="login-modal"
        onRequestClose={openModal}
        style={{
          content: {
            backgroundColor: 'bisque',
            height: '23rem',
            width: '500px',
            padding: '2rem',
            borderRadius: '8px',
            color: '#333',
          },
          overlay: {
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: '999',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '',
          },
        }}
      >
        {isLogin ? (
          <Login handleModal={openModal} authState={isSignUp} />
        ) : (
          <SignUp handleModal={openModal} />
        )}
      </Modal>

      <div className="mobileNavAbove">
        {isAuthenticated && (
          <>
            <Link to={`/generator`} className="link-mypage">
              <p>AI Art Generator</p>
            </Link>
            <p className="link-mypage divide">|</p>
            <Link to={`/${nickName}`} className="link-mypage">
              <p>My Storybook</p>
            </Link>
          </>
        )}
      </div>
      <div className="flex-wrapper">
        <div>
          <div className="App-header">
            <div className="imgContainer">
              <>
                {
                  isExist ? (
                    <div className="personal-page">
                      <h2>My Storybook</h2>
                      <h3>storybookart.ai/{`${username}`}</h3>
                      <div className="image-list">
                        {Object.keys(imageList).length ? (
                          Object.entries(imageList).map(([date, images], i) => (
                            <div className="groupby-date" key={date}>
                              <h4 className="image-date" key={i}>
                                {date}
                              </h4>
                              {images.map((image, index) => (
                                <ImageContainer
                                  key={index}
                                  image={image}
                                  setImageList={setImageList}
                                  passUpdatedTags={passUpdatedTags}
                                  filteredTags={filteredTags}
                                  filteredTag={filteredTag}
                                  isAllowed={isAllowed}
                                />
                              ))}
                            </div>
                          ))
                        ) : (
                          <>
                            <h5>No images saved yet.</h5>
                            <img src="/images/empty.png" />
                          </>
                        )}
                      </div>
                    </div>
                  ) : null // loading
                }
              </>
            </div>
          </div>

          <p className="footerText">
            © {new Date().getFullYear()}{' '}
            <a href="https://b-average.com/" target="_blank" rel="noreferrer">
              B Average Inc.
            </a>
          </p>
        </div>
        <div className={`tag-container ${isOpened ? 'mobile-responsive' : ''}`}>
          <TagsFilter
            setImageList={setImageList}
            passUpdatedTag={passUpdatedTag}
            filteredTags={filteredTags}
            setFilteredTag={setFilteredTag}
            userId={personalData.userId}
          />
        </div>
      </div>
    </div>
  );
};

export default PersonalPage;
