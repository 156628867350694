import api from '../../utils/api';
import setAuth from '../../utils/setAuth';

const TagsFilter = ({
  setImageList,
  filteredTags,
  passUpdatedTag,
  isOpened,
}) => {
  const filterImages = async (tag) => {
    const {
      tokens: {
        access: { token },
      },
    } = JSON.parse(localStorage.getItem('user_info'));

    if (token) {
      setAuth(token);
      try {
        const {
          data: { images },
        } = await api.get(`images/filter/${tag}`);
        setImageList(images);
        passUpdatedTag(tag);
      } catch (error) {
        console.warn(error);
      }
    }
  };
  return (
    <div className="tag-side">
      {filteredTags.length ? (
        <>
          <div className="tag-content">
            <i
              className="fa-solid fa-tag tag-icon"
              onClick={() => filterImages('All')}
            >
              <label className="tag-name">All</label>
            </i>
            <div className="tag-counter">{filteredTags.length}</div>
          </div>

          {filteredTags.map((filter, index) => (
            <div className="tag-content" key={index}>
              <i
                className="fa-solid fa-tag tag-icon"
                onClick={() => filterImages(filter.tag)}
              >
                <label className="tag-name">{filter.tag}</label>
              </i>
              <div className="tag-counter">{filter.count}</div>
            </div>
          ))}
        </>
      ) : (
        <h5>No tags created.</h5>
      )}
    </div>
  );
};
export default TagsFilter;
