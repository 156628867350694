import React, { useState } from 'react';
import api from '../../utils/api';
import setAuth from '../../utils/setAuth';

const ImageContainer = ({
  image,
  setImageList,
  passUpdatedTags,
  filteredTag,
  isAllowed,
}) => {
  const [isEditable, setEditable] = useState(false);
  const [editableTag, setEditableTag] = useState([]);
  const [displayedTag, setDisplayedTag] = useState('');
  const [isSpecialChar, setSpecialChar] = useState(false);
  const [isSharpSymbol, setSharpSymbol] = useState(false);

  const { _id, prompt, url, tag } = image;

  const handleKeyPress = (event) => {
    if (event.charCode === 0x20 || event.charCode === 0x2c)
      setSpecialChar(true);
    else setSpecialChar(false);
    if (event.charCode === 0x23) setSharpSymbol(true);
    else setSharpSymbol(false);
  };

  const handleOnChange = (event) => {
    const editValue = event.target.value.replace(/,/g, ' ');
    if (
      editValue.charAt(editValue.length - 2) === '#' &&
      (isSharpSymbol || isSpecialChar)
    )
      return;
    if (!displayedTag && editValue.charAt(editValue.length - 1) !== '#') {
      setDisplayedTag('#' + editValue);
      return;
    }
    setDisplayedTag(editValue);
    setEditableTag([editValue.replace(/#/g, '')]);
    if (isSpecialChar) {
      setDisplayedTag(editValue + '#');
      setSpecialChar(false);
    }
  };

  const handleOnBlur = (event) => {
    setEditable(false);
    saveTag(_id);
  };

  const handleOnFocus = (event) => {
    setEditable(true);
  };

  const saveTag = async (id) => {
    const {
      tokens: {
        access: { token },
      },
    } = JSON.parse(localStorage.getItem('user_info'));

    if (token) {
      setAuth(token);
      const saveTag = editableTag.toString().replaceAll('#', '').split(/[,\s]/);
      console.log(saveTag);
      const {
        data: { image: images, filter: updatedTags },
      } = await api.put(`/images/${id}/${filteredTag}`, { tag: saveTag });
      setImageList(images);
      passUpdatedTags(updatedTags);
    }
  };

  const editTag = (tag) => {
    if (!isEditable) {
      const initialValue = tag
        .map((t) => {
          return t ? `#${t}` : t;
        })
        .join(' ');
      setDisplayedTag(initialValue);
    }
    setEditable(true);
  };

  const deleteImage = async (id) => {
    const {
      tokens: {
        access: { token },
      },
    } = JSON.parse(localStorage.getItem('user_info'));
    if (token) {
      setAuth(token);
      try {
        const {
          data: { image: images, filter: updatedTags },
        } = await api.delete(`images/${id}/${filteredTag}`);
        setImageList(images);
        passUpdatedTags(updatedTags);
      } catch (error) {
        console.warn(error);
      }
    }
  };

  return (
    <div className="groupby-date" key={_id}>
      <h5>"{prompt}"</h5>
      <img src={url} alt="Generated image" />
      <div className="image-detail flex-container">
        <div className="tag-wrapper" onClick={() => editTag(tag)}>
          {isEditable ? (
            <input
              id="editableTag"
              placeholder="#tags"
              name="editableTag"
              value={displayedTag}
              type="text"
              autoComplete="off"
              onKeyPress={handleKeyPress}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onFocus={handleOnFocus}
              className="editabletagbox"
              required="required"
            />
          ) : tag[0] ? (
            tag.map((t, index) => {
              return <span key={index}>#{t} </span>;
            })
          ) : (
            <span>#tags</span>
          )}
        </div>
        {isAllowed && (
          <div className="delete-btn">
            <i
              className="fa-solid fa-trash"
              aria-hidden="true"
              title="Delete image"
              onClick={() => deleteImage(_id)}
            ></i>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageContainer;
