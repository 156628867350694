import axios from 'axios';
import { useState } from 'react';
import './index.scss';

const SignUp = ({ handleModal }) => {
  const [userInfo, setInfo] = useState();

  const handleChange = (e) => {
    setInfo({ ...userInfo, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      const {
        data: { tokens },
      } = await axios.post('v1/auth/register', userInfo);
      handleModal();
      localStorage.setItem('token', tokens);
    } catch (error) {
      throw error;
    }
  };

  return (
    <div className="sign-up">
      <div className="input-item">
        <p>Username: </p>
        <input type="text" name="name" onChange={handleChange} />
      </div>
      <div className="input-item">
        <p>Email: </p>
        <input type="text" name="email" onChange={handleChange} />
      </div>
      <div className="input-item">
        <p>Password: </p>
        <input type="password" name="password" onChange={handleChange} />
      </div>
      <button onClick={handleSubmit}>SUBMIT</button>
    </div>
  );
};

export default SignUp;
